<template>
  <div class="container-fluid">
    <div class="row justify-content-end">
      <router-link :to="{ name: 'accounts.speakers.create' }" class="btn btn-xs btn-primary mg-b-15 float-right">{{ $t('speakers.create')}}</router-link>
    </div>
    <div class="placeholder-paragraph aligned-centered" v-if="loading">
        <div class="line"></div>
        <div class="line"></div>
      </div>
    <div class="row" v-if="!loading">
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Name</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="speaker in speakers" v-bind:key="speaker.id">
          <td>
            <router-link :to="{name: 'accounts.speakers.edit', params: {id: speaker.id}}">{{ speaker.name }}</router-link>
          </td>
          <td class="text-right">
            <fa :icon="['fa', 'trash-alt']" v-on:click="deleteSpeaker(speaker)"/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import axios from 'axios'

  export default {
    middleware: 'auth',
    data: () => ({
      loading: true
    }),
    computed: mapGetters({
      speakers: 'account/speakers'
    }),
    created() {
      if (this.speakers.length == 0) {
        this.$store.dispatch('account/fetchSpeakers')
          .then(() => { this.loading = false; });
      } else {
        this.loading = false;
      }
    },
    methods: {
      async deleteSpeaker(speaker) {   
        try{
          this.loading = true;

          await axios.delete('/api/v1/user/me/speakers/' +  speaker.id);

          this.$store.dispatch('account/fetchSpeakers')
            .then(() => { this.loading = false; });
        } catch (e) {
          // this.$router.push({name: 'accounts.church.invite'});
        }
      }
    }
  }
</script>
